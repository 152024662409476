import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// iconos
import { BsPlus, BsEyeFill } from "react-icons/bs";
// context
import { CartContext } from "../contexts/CartContext.js";

import { Box, Link, Card, Stack, Typography, Button } from "@mui/material";
import { MdVisibility, MdOutlineAddShoppingCart } from "react-icons/md";
import moment from "moment";

import Label from "./label/label.jsx";
import { size } from "lodash";
import logoLovely from "../img/lovely.png";

export const Product = ({ product }) => {
  // Desestructuración de product
  const { addToCart } = useContext(CartContext);
  const { id, imagen, categoria, nombre, precioVenta } = product;

  return (
    <div>
      <div className="border border-[#e4e4e4] h-[300px] mb-4 relative overflow-hidden group transition">
        <div className="w-full h-full flex justify-center items-center">
          {/* imagen */}
          <div className="w-[200px] mx-auto flex justify-center items-center">
            <img
              className="max-h-[160px] group-hover:scale-110 transition duration-300"
              src={imagen}
              alt={nombre}
            />
          </div>
          {/* Botones */}
          <div className="absolute top-6 -right-10 group-hover:right-1 p-2 flex flex-col items-center justify-center gap-y-2 opacity-0 group-hover:opacity-100 transition-all duration-300">
            <button onClick={() => addToCart(product, id)}>
              <div className="flex justify-center items-center text-white w-12 h-12 bg-red-500">
                <BsPlus className="text-3xl" />
              </div>
            </button>
            <Link
              to={`/product/${id}`}
              className="w-12 h-12 bg-white flex justify-center items-center text-primary drop-shadow-xl"
            >
              <BsEyeFill />
            </Link>
          </div>
        </div>
      </div>
      {/* Categoria, titulo y precio */}
      <div>
        <div className="text-sm capitalize text-gray-500 mb-1">{categoria}</div>
        <Link to={`/product/${id}`}>
          <h2 className="font-semibold mb-1">{nombre}</h2>
        </Link>
        <div className="font-semibold">$ {precioVenta}</div>
      </div>
    </div>
  );
};

export default function ShopProductCard({ product }) {
  const { addToCart } = useContext(CartContext);
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);
  const [isNew, setIsNew] = useState(false);

  const lovelo = "./fonts/Lovelo-Black.otf";
  const cursiva2 = "../fonts/Nautilus.otf";
  useEffect(() => {
    const now = moment();
    const productcreation = moment(product.fechaCreacion);
    const diffDays = productcreation.diff(now, "days");

    setIsNew(diffDays > -15 ? true : false);
  }, [product.fechaCreacion]);

  const renderStatus = (
    <Label
      variant="filled"
      sx={{
        zIndex: 9,
        top: 16,
        right: 16,
        position: "absolute",
        textTransform: "uppercase",
      }}
    >
      {product.nombre}
    </Label>
  );

  const renderImg = (
    <Box
      component="img"
      alt={product.nombre}
      src={size(product.imagen) > 1 ? product.imagen : logoLovely}
      sx={{
        top: 0,
        width: 1,
        height: 1,
        objectFit: "cover",
        position: "absolute",
        borderTopRightRadius: 35,
        borderTopLeftRadius: 35,
      }}
    />
  );

  const renderPrice = (
    <Typography variant="subtitle1">
      <Typography
        component="span"
        variant="body1"
        sx={{
          textDecoration: "line-through",
        }}
      >
        {/* {product.precioVenta && fCurrency(product.precioVenta)} */}
      </Typography>
      &nbsp; L.{product.precioVenta}
    </Typography>
  );

  return (
    <Card
      className="hover:scale-105 transition-all relative"
      style={{
        backgroundColor: "#fbf5fb",
        borderRadius: 35,
        maxHeight: "auto",
        overflow: "visible",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box
        sx={{
          pt: "100%",
          position: "relative",
          borderRadiu: 35,
          overflow: "visible",
        }}
      >
        {/* Renderizamos el tag NEW! si el producto es nuevo */}

        {product.status && renderStatus}

        {renderImg}

        {/* Botones visibles en móviles y con hover en pantallas grandes */}

        <div
          style={{
            position: "absolute",
            top: 15, // Posicionar en la parte superior
            right: 10, // Posicionar a la derecha
            display: "flex",
            flexDirection: "column", // Alinear los botones en forma de columna
            gap: 10, // Espaciado entre botones
            opacity: hovered || window.innerWidth <= 768 ? 1 : 0, // Visibilidad en móviles
            transition: "opacity 0.3s ease-in-out, transform 0.5s ease-in-out",
          }}
        >
          <Button
            onClick={() => {
              navigate(`/product/${product.id}`);
            }}
            size="large"
            variant="contained"
            style={{ borderRadius: 25, visibility: product.active}}
            color="secondary"
            disabled={product.active}
          >
            <MdVisibility fontSize="large"></MdVisibility>
          </Button>
          <Button
            onClick={() => {
              console.log("Agregar al carrito");
              addToCart(product, product.id, 1);
            }}
            size="large"
            variant="contained"
            style={{ backgroundColor: "#facfea", borderRadius: 25 }}
            // color="primary"
            disabled={product.active}
          >
            <MdOutlineAddShoppingCart fontSize="large"></MdOutlineAddShoppingCart>
          </Button>
        </div>
        {isNew && (
          <div
            className="lovelo"
            style={{
              position: "absolute",
              top: -15,
              left: -15,
              backgroundColor: "#9c27b0",
              color: "white",
              padding: "5px 10px",
              borderRadius: "50%",
              fontWeight: "bold",
              fontSize: "12px",
              width: 60,
              height: 60,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1,
              fontFamily: cursiva2,
              clipPath:
                "polygon(48.4% -0.4%, 61% 19.8%, 79.4% 8%, 80.6% 29.8%, 98.2% 35.6%, 84.2% 49.4%, 95.4% 62.4%, 78.2% 68.4%, 79.6% 88.8%, 61.4% 82.4%, 49.2% 99.4%, 38.4% 81.4%, 19.8% 89%, 25% 67.4%, 5.8% 60.8%, 20.8% 48.6%, 5.8% 36.2%, 23.6% 28%, 19.2% 10.2%, 39.6% 19%)",
            }}
          >
            NEW
          </div>
        )}
      </Box>

      <Stack spacing={0} sx={{ p: 2 }}>
        <Stack
          color="inherit"
          underline="hover"
          variant="subtitle2"
          noWrap
          style={{
            marginTop: -10,
            color: "#9c27b0",
            fontFamily: cursiva2,
          }}
          className="cursiva"
        >
          {product.categoria}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            display: "inline",
            fontFamily: lovelo,
          }}
          className="lovelo"
        >
          {product.nombre}
        </Stack>
        <Stack
          direction="row"
          justifyContent="end"
          style={{ color: "#9c27b0" }}
        >
          {renderPrice}
        </Stack>
      </Stack>
    </Card>
  );
}
