import { Carousel } from "flowbite-react";

import logoLovely from "../img/lovely.png";
export function CarrucelIMG({ imagenes }) {
  return (
    <div className="w-full h-64 sm:h-80 md:h-96 lg:h-[20rem] xl:h-[25rem]">
      <Carousel
        pauseOnHover
        slideInterval={3000}
        leftControl={imagenes.length >= 2 ? "<" : " "}
        rightControl={imagenes.length >= 2 ? ">" : " "}
        className="h-full"
        indicators={imagenes.length >= 2 ? true : false}
      >
        {imagenes.length >= 1 ? (
          imagenes.map((item, index) => (
            <div
              key={index}
              className="w-full h-full flex justify-center items-center"
            >
              <img
                src={item}
                alt="..."
                className="h-full object-cover" 
              />
            </div>
          ))
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <img
              src={logoLovely}
              alt="..."
              className="h-full object-cover"
            />
          </div>
        )}
      </Carousel>
    </div>
  );
}
