import React from 'react';
import { PDFDownloadLink, Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';
// context
import LOGO from '../img/lovely.png';
import { size } from 'lodash';

// Estilos para el PDF
const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontFamily: 'Helvetica',
    backgroundColor: '#facfea'
  },
  header: {
    marginBottom: 20,
    textAlign: 'center',
    alignItems: 'center', // Asegúrate de centrar el contenido
  },
  logo: {
    width: '100px', // Ajusta el tamaño del logo según sea necesario
    marginBottom: 10,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  clientInfoRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    marginBottom: 20,
  },
  table: {
    width: '100%',
    borderWidth: 1,
    borderColor: '#000',
    marginBottom: 20,
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#9c27b0',
    textAlign: 'center',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCellHeader: {
    flex: 1,
    padding: 5,
    fontSize: 12,
    fontWeight: 'bold',
    borderBottomWidth: 1,
    borderColor: '#000',
    color: "#ffffff"
  },
  tableCell: {
    flex: 1,
    padding: 5,
    fontSize: 15,
    borderBottomWidth: 1,
    borderColor: '#000',
  },
  productImage: {
    width: '100px',
    height: '100px',
    marginRight: 10,
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    height: 40,
    backgroundColor: 'black',
  },
  footerText: {
    color: 'white',
    textAlign: 'center',
    lineHeight: 40,
  },
});

// Componente PDF
const PDF = ({ cartItems, cliente }) => (
  <Document>
    <Page style={styles.page}>
      {/* Encabezado con logo y datos del cliente */}
      <View style={styles.header}>
        <Image src={LOGO} style={styles.logo} />
        <View style={styles.clientInfoRow}>
          <Text>Pedido de: {size(cliente) > 0 ? cliente.name : 'Cliente'}</Text>
          <Text>Teléfono: {size(cliente) > 0 ? cliente.phone : 'N/A'}</Text>
          <Text>Correo: {size(cliente) > 0 ? cliente.email : 'N/A'}</Text>
        </View>
      </View>

      {/* Tabla de productos */}
      <View style={styles.table}>
        <View style={styles.tableHeader}>
          <Text style={styles.tableCellHeader}>IMAGEN</Text>
          <Text style={styles.tableCellHeader}>PRODUCTO</Text>
          <Text style={styles.tableCellHeader}>CANTIDAD</Text>
        </View>
        {cartItems.map((item, index) => (
          <View
            style={[
              styles.tableRow,
              { backgroundColor: index % 2 === 0 ? '#fabbe3' : '#facfea' }, // Alternancia de colores
            ]}
            key={index}
          >
            <View style={styles.tableCell}>
              <Image src={size(item.imagen) > 1 ? item.imagen : LOGO} style={styles.productImage} />
            </View>
            <Text style={styles.tableCell}>{item.nombre}</Text>
            <Text style={styles.tableCell}>{item.amount}</Text>
          </View>
        ))}
      </View>

      {/* Pie de página
      <View style={styles.footer}>
        <Text style={styles.footerText}>Este es el pie de página</Text>
      </View> */}
    </Page>
  </Document>
);

// Componente principal
const CarritoPDF = ({ cart, cliente }) => {
  return (
    <div>
      <PDFDownloadLink document={<PDF cartItems={cart} cliente={cliente} />} fileName="pedido_cliente.pdf">
        {({ loading }) => (loading ? 'Cargando documento...' : 'Descargar PDF')}
      </PDFDownloadLink>
    </div>
  );
};

export default CarritoPDF;
