import { useContext, useState } from "react";
// context
import { ProductContext } from "../contexts/ProductContext.js";
// componentes
import { Product } from "../components/Product.js";
import ShopProductCard from "../components/Product.js";
import { Select, MenuItem, FormControl, InputLabel, InputAdornment,  } from "@mui/material";

import 'primeicons/primeicons.css';
        


export const Products = () => {
  const { products, categories } = useContext(ProductContext);
  const [selectedCategory, setSelectedCategory] = useState("Todos");

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const filteredProducts =
    selectedCategory === "Todos"
      ? products
      : products.filter((product) => product.categoria === selectedCategory);
  console.log(selectedCategory, products.categoriaID);

  return (
    <section className="mt-[60px] sm:mt-8 lg:mt-[10px] max-w-[1100px] mx-auto py-8 sm:py-12 lg:py-16">
      <div className="container mx-auto">
        <div className="text-center mb-5 sm:mb-10">
          <h3 className="text-2xl sm:text-3xl lg:text-4xl leading-normal font-extrabold tracking-tight text-white lovelo">
            Todos Nuestros <span style={{ color: "#9c27b0" }}>Productos</span>
          </h3>

          {/* Filtro de categorías a la par del título */}
          <FormControl
            variant="outlined"
            sx={{ minWidth: 200, marginTop: 2 }}
            className="mt-4 sm:mt-0"
          >
            <InputLabel
              id="category-select-label"
              sx={{
                color: "#9c27b0", // Cambia el color del label en estado normal
                "&.Mui-focused": {
                  color: "#9c27b0", // Cambia el color del label cuando el select está enfocado
                },
              }}
            >
              Categoría
            </InputLabel>
            <Select
              labelId="category-select-label"
              value={selectedCategory}
              onChange={handleCategoryChange}
              label="Categoría"
              size="small" // tamaño pequeño para que se ajuste mejor
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: "#facfea", // Cambiar fondo del menú desplegable
                    "& .MuiMenuItem-root": {
                      color: "#9c27b0", // Cambiar el color del texto de los elementos del menú
                    },
                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: "#facfea", // Cambiar el fondo del elemento seleccionado
                      color: "#9c27b0", // Cambiar el color del texto del elemento seleccionado
                    },
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "#f1c3e3", // Cambiar el fondo al hacer hover en un ítem
                    },
                  },
                },
              }}
              sx={{
                color: "#9c27b0", // Cambiar color de texto
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#9c27b0", // Cambiar color del borde
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#9c27b0", // Cambiar el color del borde cuando está enfocado
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#facfea", // Cambiar color del borde al hacer hover
                },
                ".MuiSvgIcon-root": {
                  color: "#9c27b0", // Cambiar color del icono de flecha
                },
              }}
              startAdornment={(<i className="pi pi-search"></i>)}
            >
              <MenuItem key={0} value={"Todos"}>
                Todos
              </MenuItem>
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.nombre}>
                  {category.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* Listado de productos */}
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 max-w-sm mx-auto md:max-w-none md:mx-0">
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product) => (
              <ShopProductCard product={product} key={product.id} />
            ))
          ) : (
            <ShopProductCard product={{nombre: "Agotados", precioVenta: '0', fechaCreacion: 'null', categoria: 'Producto Agotado', active: true }} key={1} />
            
          )}
        </div>
      </div>
    </section>
  );
};
