import { useContext, useState } from "react";
import { Link } from "react-router-dom";
// iconos
import { IoMdArrowForward } from "react-icons/io";
import { FiTrash2 } from "react-icons/fi";
// componentes
import { CartItem } from "./CartItem.js";
// context
import { SidebarContext } from "../contexts/SidebarContext.js";
import { CartContext } from "../contexts/CartContext.js";
import { Button } from "@mui/material";
import ContactModal from "./ContactModal.js";
import CarritoPDF from "../PDF/CarritoPDF.js";


export const Sidebar = () => {
  const { isOpen, handleClose } = useContext(SidebarContext);
  const { cart, clearCart, total, itemAmount } = useContext(CartContext);
  const lovelo = "../fonts/Lovelo-Black.otf";
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <>
      <div
        style={{ backgroundColor: "#facfea" }}
        id="cart-container"
        className={`${
          isOpen ? "right-0" : "-right-full"
        } w-full fixed top-0 h-full shadow-2xl md:w-[35vw] xl:max-w-[30vw] transition-all duration-300 z-20 px-4 lg:px-[35px] lovelo`}
      >
        <div className="flex items-center justify-between py-3 border-b border-[#000000]">
          <div className="uppercase text-sm font-semibold">
            Carrito de Compra ({itemAmount})
          </div>
          <div
            onClick={handleClose}
            className="cursor-pointer w-8 h-8 flex justify-center items-center"
          >
            <IoMdArrowForward className="text-2xl" />
          </div>
        </div>
        <div  className="flex flex-col gap-y-2 h-[550px] lg:h-[500px] overflow-y-auto overflow-x-hidden border-b border-[#000000]">
          {cart.map((item) => (
            <CartItem item={item} key={item.id} />
          ))}
        </div>

        <div className="flex flex-col gap-y-3 py-4 mt-1">
          <div className="flex w-full justify-between items-center">
            <div className="uppercase font-semibold">
              <span className="mr-2">Total:</span>L.{" "}
              {parseFloat(total).toFixed(2)}
            </div>
            <div
              onClick={clearCart}
              className="cursor-pointer py-4 bg-red-500 text-white w-12 h-12 flex justify-center items-center text-xl rounded-full"
            >
              <FiTrash2 />
            </div>
          </div>
          <Button
            onClick={handleOpenModal}
            className="lovelo"
            variant="contained"
            style={{ backgroundColor: "#9c27b0", fontFamily: lovelo }}
          >
            Pedir Productos
          </Button>
          <ContactModal open={modalOpen} handleClose={handleCloseModal} />
          <CarritoPDF cart={cart}></CarritoPDF>
        </div>
      </div>
    </>
  );
};
