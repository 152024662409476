import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  styled,
} from "@mui/material";

// Estilos para el modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  backgroundColor: "#facfea"
};

const ContactModal = ({ open, handleClose }) => {
  const lovelo = "../fonts/Lovelo-Black.otf";
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log({ name, phone, email });
    // Aquí puedes agregar la lógica para manejar los datos
    handleClose(); // Cerrar el modal después de enviar
  };

  const CustomTextField = styled(TextField)(({ theme }) => ({}));

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2" gutterBottom style={{color: "#9c27b0", textAlign: 'center', textTransform: 'uppercase'}}>
        ayúdanos a contáctarte
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nombre"
                variant="outlined"
                margin="normal"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#9c27b0", // Cambiar el color del borde
                    },
                    "&:hover fieldset": {
                      borderColor: "#9c27b0", // Color del borde al pasar el ratón
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#9c27b0", // Color del borde cuando está enfocado
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "#9c27b0", // Cambiar el color de la etiqueta
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#9c27b0", // Color de la etiqueta cuando está en foco
                  },
                  '& input': {
                    color: '#9c27b0', // Cambiar el color del texto
                  },
              
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Whatsapp"
                variant="outlined"
                margin="normal"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#9c27b0", // Cambiar el color del borde
                    },
                    "&:hover fieldset": {
                      borderColor: "#9c27b0", // Color del borde al pasar el ratón
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#9c27b0", // Color del borde cuando está enfocado
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "#9c27b0", // Cambiar el color de la etiqueta
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#9c27b0", // Color de la etiqueta cuando está en foco
                  },
                  '& input': {
                    color: '#9c27b0', // Cambiar el color del texto
                  },
              
                }}
              />
            </Grid>
          </Grid>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            margin="normal"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#9c27b0", // Cambiar el color del borde
                },
                "&:hover fieldset": {
                  borderColor: "#9c27b0", // Color del borde al pasar el ratón
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#9c27b0", // Color del borde cuando está enfocado
                },
              },
              "& .MuiInputLabel-root": {
                color: "#9c27b0", // Cambiar el color de la etiqueta
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#9c27b0", // Color de la etiqueta cuando está en foco
              },
              '& input': {
                color: '#9c27b0', // Cambiar el color del texto
              },
          
            }}
          />
          <Button
            type="submit"
            variant="contained"
            className="lovelo"
            fullWidth
            style={{ backgroundColor: "#9c27b0", fontFamily: lovelo }}
          >
            Enviar
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default ContactModal;
