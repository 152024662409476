import { createContext, useState, useEffect } from "react";

export const ProductContext = createContext();

const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await fetch("https://apilovelyhn.fly.dev/api/productos");
      const data = await response.json();
      setProducts(data.Productos);
    };


    const fetchCategorias = async () => {
      const response = await fetch("https://apilovelyhn.fly.dev/api/categorias");
      const data = await response.json();
      setCategories(data.Categorias);
    };
    fetchCategorias()
    fetchProducts();


  }, []);

  return (
    <ProductContext.Provider value={{ products,categories }}>
      {children}
    </ProductContext.Provider>
  );
};

export default ProductProvider;
