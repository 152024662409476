import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// Páginas
//import { Home, ProductDetails, Products, About, Contact, Error } from "./pages";
import {Home} from "./pages/Home.js";
import { ProductDetails } from "./pages/ProductDetails.js";
import {Products} from "./pages/Products.js"
import {About} from "./pages/About.js"
import {Contact} from "./pages/Contact.js"
import {Error} from "./pages/Error.js"
// Componentes
//import { Sidebar. Header. Footer } from "./components";
import { Sidebar } from "./components/Sidebar.js";
import { Header } from "./components/Header.js";
import { Footer } from "./components/Footer.js";

const App = () => {
  return (
    <>
      <div className="overflow-hidden">
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Products />} />
            <Route path="/product/:id" element={<ProductDetails />} />
            <Route path="/products" element={<Products />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<Error />} />
          </Routes>
          <Sidebar />
          
        </Router>
      </div>
      <Footer />
    </>
  );
};

export default App;
